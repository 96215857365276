import React, { useEffect } from 'react'
import { Block, Text } from 'components/atoms'
import { AddressCard } from 'components/molecules'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import AddressModal from '../AddressModal'
import DeleteAddressModal from '../DeleteAddressModal'

// REDUX
import { deleteAddress } from 'modules/auth/actions'
import { getGeo } from 'modules/geo/actions'
import { connect } from 'react-redux'
import { selectDeleteAddressStatus } from 'modules/auth/selectors'
import { selectGeo } from 'modules/geo/selectors'
import { bindActionCreators } from 'redux'

const AddressSection = ({
  geo,
  getGeo,
  addresses,
  deleteAddressStatus,
  deleteAddress,
}) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [editAddress, setEditAddress] = React.useState(null)
  const [addressId, setAddressId] = React.useState(null)

  useEffect(() => {
    if (!geo) {
      getGeo()
    }
  }, [])

  return (
    <Block>
      <Block py={'5px'} width={'100%'} background={'#dddddd'} mt={20}>
        <Text ml={15}>ที่อยู่</Text>
      </Block>
      <Block
        p={[16, 20]}
        overflowX={'auto'}
        display={'flex'}
        maxWidth={['auto', '100%', '100%']}
        minHeight={150}
        flexDirection={['column', 'row']}
      >
        {addresses.map(address => (
          <AddressCard
            address={address}
            editable
            deletable={!address.defaultAddress}
            key={address.id}
            setOpenDeleteModal={() => {
              setAddressId(address.id)
              setOpenDeleteModal(true)
            }}
            setEditAddress={() => {
              setOpenModal(true)
              setEditAddress(address)
            }}
          />
        ))}
        <Block>
          <Block
            onClick={() => setOpenModal(true)}
            style={{ cursor: 'pointer' }}
            width={['auto', 360]}
            height={[150, '100%']}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            border={'1px dashed #bababa'}
            borderRadius={'8px'}
          >
            <AddCircleOutline style={{ color: '#bababa' }} />
          </Block>
        </Block>
      </Block>
      <AddressModal
        open={openModal}
        onClose={() => {
          if (editAddress) setEditAddress(null)
          setOpenModal(false)
        }}
        geo={geo}
        editAddress={editAddress}
        defaultAddress={addresses.length === 0}
        setEditAddress={setEditAddress}
        canEditDefaultAddress={addresses.length > 0}
      />
      <DeleteAddressModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false)
          setAddressId(null)
        }}
        deleteAddress={deleteAddress}
        deleteAddressStatus={deleteAddressStatus}
        addressId={addressId}
      />
    </Block>
  )
}

const mapStateToProps = state => ({
  geo: selectGeo(state),
  deleteAddressStatus: selectDeleteAddressStatus(state),
})

export default connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      {
        getGeo,
        deleteAddress,
      },
      dispatch
    )
)(AddressSection)

import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import InfiniteScroll from 'react-infinite-scroller'
import CircularProgress from '@material-ui/core/CircularProgress/index'
import { Block, Text, GatsbyImage } from 'components/atoms'
import RedeemDetail from 'components/ecosystems/RedeemDetail'
import { HistoryList, RedemptionList } from 'components/molecules'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// REDUX
import { getTransactions, getRedemptions } from 'modules/history/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  selectTransactionData,
  selectRedemptionData,
  selectShouldGetMoreTransaction,
  selectShouldGetMoreRedemption,
  selectGetGetTransactionStatus,
  selectGetGetRedemptionStatus,
} from 'modules/history/selectors'

// Image
const noResult = 'no_result.jpg'

const HistorySection = ({
  backToCard,
  getRedemptions,
  getTransactions,
  transactions,
  getTransactionStatus,
  shouldGetMoreTransaction,
  redemptions,
  getRedemptionStatus,
  shouldGetMoreRedemption,
}) => {
  const [transactionDebouce, setTransactionDebouce] = useState(true)
  const [redemptionDebouce, setRedemptionDebouce] = useState(true)
  const [activeRedemption, setActiveRedemption] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [state, setState] = useState('history')

  const intl = useIntl()
  // useEffect(() => {
  // 	getRedemptions()
  // 	getTransactions()
  // }, [])

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue)
  }

  const getTransactionMore = () => {
    setTransactionDebouce(false)
    getTransactions().then(() => {
      setTransactionDebouce(true)
    })
  }

  const getRedemptionMore = () => {
    setRedemptionDebouce(false)
    getRedemptions().then(() => {
      setRedemptionDebouce(true)
    })
  }
  return (
    <Block p={20}>
      {state === 'history' && (
        <Block>
          <Text link onClick={() => backToCard()}>{`< กลับสู่ My Card`}</Text>
          <Text my={20} variant={'h1'}>
            {intl.formatMessage({ id: 'historyCollect/redeem' })}
          </Text>
          <Tabs
            value={activeTab}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            style={{ marginBottom: 25 }}
          >
            <Tab
              label={intl.formatMessage({ id: 'collectPointHistory' })}
            ></Tab>
            <Tab label={intl.formatMessage({ id: 'redeemHistory' })}></Tab>
          </Tabs>
          {activeTab === 0 && (
            <Block>
              {!getTransactionStatus.isFulfilled && transactions.length === 0 && (
                <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
                  <CircularProgress size={30} thickness={5} />
                </Block.Flex>
              )}
              {getTransactionStatus.isFulfilled && transactions.length === 0 && (
                <Block textAlign={'center'}>
                  <GatsbyImage
                    src={noResult}
                    mt={25}
                    height={['auto', 400]}
                    width={['auto', 400]}
                    m={'auto'}
                  />
                  <Text>{intl.formatMessage({ id: 'noHistory' })}</Text>
                </Block>
              )}
              <InfiniteScroll
                loadMore={() => getTransactionMore()}
                hasMore={transactionDebouce && shouldGetMoreTransaction}
                loader={
                  <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
                    <CircularProgress size={20} thickness={5} />
                  </Block.Flex>
                }
              >
                <Block>
                  {transactions.length > 0 &&
                    transactions.map(transaction => (
                      <HistoryList history={transaction} />
                    ))}
                </Block>
              </InfiniteScroll>
            </Block>
          )}
          {activeTab === 1 && (
            <Block>
              {!getRedemptionStatus.isFulfilled && redemptions.length === 0 && (
                <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
                  <CircularProgress size={30} thickness={5} />
                </Block.Flex>
              )}
              {getRedemptionStatus.isFulfilled && redemptions.length === 0 && (
                <Block textAlign={'center'}>
                  <GatsbyImage
                    src={noResult}
                    mt={25}
                    height={['auto', 400]}
                    width={['auto', 400]}
                    m={'auto'}
                  />
                  <Text>{intl.formatMessage({ id: 'noHistory' })}</Text>
                </Block>
              )}
              <InfiniteScroll
                loadMore={() => getRedemptionMore()}
                hasMore={redemptionDebouce && shouldGetMoreRedemption}
                loader={
                  <Block.Flex key={'loader'} justifyContent={'center'} p={20}>
                    <CircularProgress size={20} thickness={5} />
                  </Block.Flex>
                }
              >
                <Block>
                  {redemptions.length > 0 &&
                    redemptions.map(redemption => (
                      <Block
                        onClick={() => {
                          setState('redeemDetail')
                          setActiveRedemption(redemption)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <RedemptionList history={redemption} redemption />
                      </Block>
                    ))}
                </Block>
              </InfiniteScroll>
            </Block>
          )}
        </Block>
      )}
      {state === 'redeemDetail' && activeRedemption !== null && (
        <RedeemDetail
          goBack={() => setState('history')}
          redemption={activeRedemption}
        />
      )}
    </Block>
  )
}

export default connect(
  state => ({
    transactions: selectTransactionData(state),
    shouldGetMoreTransaction: selectShouldGetMoreTransaction(state),
    redemptions: selectRedemptionData(state),
    shouldGetMoreRedemption: selectShouldGetMoreRedemption(state),
    getTransactionStatus: selectGetGetTransactionStatus(state),
    getRedemptionStatus: selectGetGetRedemptionStatus(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        getTransactions,
        getRedemptions,
      },
      dispatch
    )
)(HistorySection)

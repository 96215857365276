import React from 'react'
import { Block } from 'components/atoms'
import RewardCard from 'components/molecules/RewardCard'

const RewardSection = ({ rewards = [], setReward }) => {
  return (
    <Block>
      <Block
        p={15}
        display={['flex']}
        flexWrap="wrap"
        justifyContent={['center', 'flex-start', 'flex-start']}
      >
        {rewards.map(reward => (
          <Block
            key={reward.id}
            style={{ cursor: 'pointer' }}
            onClick={() => setReward(reward.id)}
            margin={['10px']}
            marginTop={['15px', '10px']}
            marginBottom={['15px', '10px']}
          >
            <RewardCard reward={reward} />
          </Block>
        ))}
      </Block>
    </Block>
  )
}

export default RewardSection

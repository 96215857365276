import React, { useState, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import 'moment/locale/th'
import Divider from '@material-ui/core/Divider'
import { Block, Text, Button, Image } from 'components/atoms'
import RedeemRewardModal from '../RedeemRewardModal'
import RedeemRewardSuccessModal from '../RedeemRewardSuccessModal'
import { animateScroll as scroll } from 'react-scroll'

moment.locale('th')

const RewardDetail = ({ reward, backToCard, userPoint }) => {
  useEffect(() => {
    scroll.scrollToTop({
      duration: 750,
      smooth: true,
    })
  }, [])

  const {
    name,
    point,
    image,
    conditions,
    startDate,
    endDate,
    hasQuota,
    quota,
  } = reward
  const [openRedeemModal, setOpenRedeemModal] = useState(false)
  const [openRedeemSuccessModal, setOpenRedeemSuccessModal] = useState(false)

  const intl = useIntl()

  return (
    <Block p={20} style={{ wordBreak: 'break-word' }}>
      <Text onClick={() => backToCard()} link>
        {`< ${intl.formatMessage({ id: 'Back' })}`}
      </Text>
      <Image
        src={image}
        minHeight={300}
        maxHeight={350}
        mt={20}
        ml={'auto'}
        mr={'auto'}
        mb={20}
      />
      <Text variant={'h1'} mb={15}>
        {name}
      </Text>
      <Text variant={'caption'}>{point} point</Text>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <Text variant={'h4'} mb={10}>
        {intl.formatMessage({ id: 'conditionForRedeemReward' })}
      </Text>
      <Block>
        {conditions &&
          conditions.split(/\r?\n/g).map(condition => (
            <Block>
              <Text variant={'caption'} mt={14}>
                {condition}
              </Text>
            </Block>
          ))}
      </Block>
      <Block display={['flex']} justifyContent="space-between" mt={15}>
        <Block>
          <Text variant={'h4'} mb={10}>
            {intl.formatMessage({ id: 'redeemTime' })}
          </Text>
          <Text variant={'caption'}>
            {moment(startDate).format('DD MMM YYYY HH:mm')}
            {` ${intl.formatMessage({ id: 'to' })} `}
            {moment(endDate).format('DD MMM YYYY HH:mm')}
          </Text>
        </Block>
        {hasQuota && (
          <Block display="flex">
            <Text variant={'h4'} mr={'7px'}>
              {intl.formatMessage({ id: 'quota' })}
            </Text>
            <Text variant={'caption'} mt={'2px'}>
              {quota}
            </Text>
          </Block>
        )}
      </Block>
      <Block textAlign={'center'} mt={30}>
        <Button.Contain
          width={['100%', 370]}
          disabled={userPoint < point}
          onClick={() => setOpenRedeemModal(true)}
        >
          {intl.formatMessage({ id: 'redeemReward' })}
        </Button.Contain>
      </Block>
      <RedeemRewardModal
        onClose={() => setOpenRedeemModal(false)}
        open={openRedeemModal}
        reward={reward}
        userPoint={userPoint}
        setOpenRedeemSuccessModal={setOpenRedeemSuccessModal}
      />
      <RedeemRewardSuccessModal
        open={openRedeemSuccessModal}
        reward={reward}
        userPoint={userPoint}
        onClose={() => setOpenRedeemSuccessModal(false)}
      />
    </Block>
  )
}

export default RewardDetail

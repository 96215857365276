import REWARD_TYPES from './types'
import CloudApi from '../../api/cloudApi'

export const getReward = () => ({
  type: REWARD_TYPES.GET_REWARD,
  payload: CloudApi.getReward(),
})

export const redeemReward = data => ({
  type: REWARD_TYPES.REDEEM_REWARD,
  payload: CloudApi.redeemReward(data),
})

export const getRewardById = id => ({
  type: REWARD_TYPES.GET_REWARD_BY_ID,
  payload: CloudApi.getRewardById(id),
})

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import get from 'lodash/get'
import 'moment/locale/th'
import { Block, Text } from 'components/atoms'
import Chip from '@material-ui/core/Chip'

moment.locale('th')

const HistoryList = ({ history }) => {
  const intl = useIntl()
  const selectBackground = status => {
    if (status === 'pending') return '#ffab1a'
    if (status === 'cancel') return '#ff3535'
    if (status === 'waiting') return '#f15a29'
    if (status === 'shipping') return '#12a0ff'
    return '#32bb72'
  }
  return (
    <Block
      display={'flex'}
      width={'100%'}
      py={16}
      borderBottom={'1.5px solid #f7f9f9'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Block display={'flex'} alignItems={'center'} width={['50%', '20%']}>
        <Block
          width={12}
          height={12}
          background={selectBackground(history.status)}
          mr={10}
          borderRadius={'50%'}
        />
        <Text variant={'h4'}>{intl.formatMessage({ id: 'redeemPoint' })}</Text>
      </Block>
      <Block width={['50%', '15%']} textAlign={['right', 'left']}>
        <Block display={['none', 'block']}>
          <Text variant={'caption'} fontSize={['10px', '12px']}>
            {moment(history.createdAt).format('DD MMM YYYY HH:mm')}
          </Text>
        </Block>
        <Block
          display={['block', 'none']}
          css={{
            '.MuiChip-root': {
              color: 'white',
              backgroundColor: selectBackground(history.status),
            },
          }}
        >
          <Chip size="small" label={history.status} />
        </Block>
      </Block>
      <Block width={['100%', '20%']} mt={['4px', 0]}>
        <Block
          display={['flex', 'none']}
          mt={10}
          mb={10}
          justifyContent="space-between"
        >
          <Text variant={'caption'}>{intl.formatMessage({ id: 'date' })}</Text>
          <Text variant={'caption'}>
            {moment(history.createdAt).format('DD MMM YYYY HH:mm')}
          </Text>
        </Block>
        <Text variant={'caption'} style={{ wordBreak: 'break-word' }}>
          {intl.formatMessage(
            { id: 'redeemPointForReward' },
            { point: history.point, rewardName: history.reward.name }
          )}
        </Text>
      </Block>
      <Block
        width={['100%', '45%']}
        display="flex"
        justifyContent="space-between"
        mt={[10, 0]}
        alignItems="center"
      >
        <Block
          display={['block', 'flex']}
          justifyContent="space-between"
          width={['auto', '60%']}
          alignItems={['baseline', 'center']}
        >
          <Block
            display={['none', 'block']}
            css={{
              '.MuiChip-root': {
                color: 'white',
                backgroundColor: selectBackground(history.status),
              },
            }}
          >
            <Chip size="small" label={history.status} />
          </Block>
          <Block>
            <Text variant={'caption'}>
              {intl.formatMessage({ id: 'update' })}{' '}
              {moment(history.updatedAt).format('DD MMM YYYY HH:mm')}
            </Text>
          </Block>
        </Block>
        <Text variant={'caption'} color="primary">
          {intl.formatMessage({ id: 'seeDetail' })}
        </Text>
      </Block>
    </Block>
  )
}

export default HistoryList

import React from 'react'
import { Block, Text } from 'components/atoms'
import { useIntl } from 'gatsby-plugin-intl'

const RewardCard = ({ reward }) => {
  const intl = useIntl()
  const { name, point, image } = reward
  return (
    <Block width={270} flex={'0 0 auto'}>
      <Block
        width={'100%'}
        height={165}
        borderRadius={'12px 12px 0px 0px'}
        boxShadow={'0 1px 10px 0 rgba(0, 0, 0, 0.1)'}
        backgroundImage={`url(${image})`}
        backgroundSize={'cover'}
      ></Block>
      <Block
        boxShadow={'0 1px 10px 0 rgba(0, 0, 0, 0.1)'}
        borderRadius={'0px 0px 12px 12px'}
        p={15}
        height={70}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        style={{ wordBreak: 'break-word' }}
      >
        <Text fontWeight={'bold'} fontSize={14} style={{ overflow: 'hidden' }}>
          {name}
        </Text>
        <Text mt={10} color={'rgba(134,134,134,0.75)'} fontSize={10}>
          {`${point} ${intl.formatMessage({ id: 'Points' })}`}
        </Text>
      </Block>
    </Block>
  )
}

export default RewardCard

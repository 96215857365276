import React, { useState } from 'react'

import { Block, Text, Button } from 'components/atoms'
import firebase from 'appFirebase/config'
import PinInput from 'react-pin-input'
import Countdown from 'react-countdown-now'
import { navigate } from 'gatsby'

// CloudApi
import CloudApi from 'api/cloudApi'

const PhoneOtp = ({
  phoneNumber,
  setConfirmationResult,
  confirmationResult,
  getProfile,
}) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [countDownKey, setCountDownKey] = useState(null)
  const [sendOtpPending, setSendOtpPending] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  const countDownRenderer = ({ seconds, completed }) => {
    if (completed) {
      setCountDownKey(null)
      return (
        <Button
          id="resend-button"
          className={'reset-button'}
          onClick={() => handleResend()}
          loading={resendLoading}
        >
          RESEND OTP
        </Button>
      )
    } else {
      return (
        <Text variant={'caption'}>
          You'll be able to resend OTP again in {seconds} seconds
        </Text>
      )
    }
  }

  const handleOTP = otpData => {
    setSendOtpPending(true)
    return confirmationResult
      .confirm(otpData)
      .then(() => {
        return firebase
          .auth()
          .currentUser.unlink('phone')
          .then(() =>
            CloudApi.updatePhoneNumber({
              phoneNumber,
              phoneAlreadyUse: false,
            })
          )
          .then(() => {
            setSendOtpPending(false)
            getProfile()
            return navigate('/')
          })
      })
      .catch(err => {
        if (err.code === 'auth/credential-already-in-use') {
          return CloudApi.updatePhoneNumber({
            phoneNumber: phoneNumber,
            phoneAlreadyUse: true,
          }).then(() => {
            setSendOtpPending(false)
            getProfile()
            return navigate('/')
          })
        }
        if (err.code === 'auth/invalid-verification-code') {
          setErrorMessage('OTP ที่กรอกไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง')
          setSendOtpPending(false)
        } else {
          setErrorMessage('ไม่สามารถทำรายการได้ในขณะนี้กรุณาลองใหม่อีกครั้ง')
          setSendOtpPending(false)
        }
      })
  }

  const handleResend = () => {
    setResendLoading(true)
    firebase.auth().languageCode = 'en'
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId)
    })
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'resend-button',
      {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response)
        },
      }
    )
    var appVerifier = window.recaptchaVerifier
    return (
      firebase
        .auth()
        .currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
        // .then(data => console.log('data', data));
        .then(confirmationResult => {
          setResendLoading(false)
          setConfirmationResult(confirmationResult)
          setCountDownKey(Date.now())
        })
        .catch(error => {
          if (error.code === 'auth/too-many-requests') {
            setErrorMessage(
              'We have blocked all requests from this device due to unusual activity. Try again later.'
            )
          } else setErrorMessage('Something wrong,Please try later')
        })
    )
  }
  return (
    <Block textAlign={'center'}>
      <Text variant={'h2'}>ยืนยัน OTP</Text>
      <Block>
        <Text mt={15}>
          กรุณากรอก OTP ที่ส่งไปยัง
          <br />
          หมายเลข {phoneNumber && phoneNumber.replace('+66', '0')} เพื่อยืนยัน
        </Text>
      </Block>
      <Block mt={20} mb={20}>
        <Block textAlign={'center'} width={['90%', '40%']} m={'auto'}>
          <PinInput
            length={6}
            focus
            type="numeric"
            style={{ height: 40, marginBottom: 4 }}
            inputStyle={{
              width: '14%',
              height: '100%',
              border: '1px solid #acacac',
              borderRadius: 4,
            }}
            inputFocusStyle={{ border: '1px solid #EF5B34' }}
            onComplete={value => handleOTP(value)}
          />
          {errorMessage && (
            <Text color="red" variant={'caption'}>
              {errorMessage}
            </Text>
          )}
        </Block>
        <Block
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          my={10}
          height={32}
        >
          <Countdown
            key={countDownKey}
            date={countDownKey ? Date.now() + 50000 : 0}
            renderer={countDownRenderer}
          />
        </Block>
      </Block>
      <Button.Contain
        fullWidth
        loading={sendOtpPending}
        disabled={countDownKey !== null}
      >
        ยืนยัน
      </Button.Contain>
    </Block>
  )
}

export default PhoneOtp

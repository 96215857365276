import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import get from 'lodash/get'
import 'moment/locale/th'
import { Block, Text } from 'components/atoms'

moment.locale('th')

const HistoryList = ({ history, redemption }) => {
  const intl = useIntl()
  const renderTransactionType = history => {
    if (get(history, 'event')) {
      return intl.formatMessage(
        { id: 'getPointFromEvent' },
        { eventName: history.event.name }
      )
    }
    return intl.formatMessage(
      { id: 'getPointFromCode' },
      { code: history.code, campaign: history.campaign.name }
    )
  }

  return (
    <Block
      display={'flex'}
      width={'100%'}
      py={16}
      borderBottom={'1px solid #f7f9f9'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Block display={'flex'} alignItems={'center'} width={['50%', '30%']}>
        <Block
          width={12}
          height={12}
          background={!redemption ? '#6fd422' : '#f15a29'}
          mr={10}
          borderRadius={'50%'}
        />
        <Text variant={'h4'}>
          {!redemption
            ? intl.formatMessage({ id: 'getPoint' }, { point: history.point })
            : intl.formatMessage({ id: 'redeemPoint' })}
        </Text>
      </Block>
      <Block width={['50%', '25%']} textAlign={['right', 'left']}>
        <Text variant={'caption'} fontSize={['10px', '12px']}>
          {moment(history.createdAt).format('DD MMM YYYY HH:mm')}
        </Text>
      </Block>
      <Block width={['100%', '45%']} mt={['4px', 0]}>
        <Text variant={'caption'}>
          {!redemption
            ? renderTransactionType(history)
            : intl.formatMessage(
                { id: 'redeemPointForReward' },
                { point: history.point, rewardName: history.reward.name }
              )}
        </Text>
      </Block>
    </Block>
  )
}

export default HistoryList

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Block, Text, Image } from 'components/atoms'
import { Modal } from 'components/molecules'

const RedeemRewardSuccess = ({ reward, userPoint, open, onClose }) => {
  const { image, name } = reward
  const intl = useIntl()
  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <Block textAlign={'center'}>
          <Text variant={'h3'}>
            {intl.formatMessage({ id: 'congratulationGetReward' })}
          </Text>
          <Text fontWeight={'bold'} fontSize={20} mt={15} mb={15}>
            {name}
          </Text>
          <Image
            src={image}
            width={125}
            height={125}
            borderRadius={8}
            m={'auto'}
          />
          <Text mt={20}>
            {intl.formatMessage({ id: 'yourPintLeft' }, { userPoint })}
          </Text>
        </Block>
      }
    />
  )
}

export default RedeemRewardSuccess

import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'gatsby-plugin-intl'
import { Block, Image, Text } from 'components/atoms'
import { AddressCard, RewardCard } from 'components/molecules'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { withStyles, makeStyles } from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector'
import SettingsIcon from '@material-ui/icons/CardGiftcard'
import GroupAddIcon from '@material-ui/icons/AccessTime'
import VideoLabelIcon from '@material-ui/icons/LocalShipping'
import CheckIcon from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 28,
  },
  active: {
    '& $line': {
      background: '#ef5b34',
    },
  },
  completed: {
    '& $line': {
      background: '#ef5b34',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 55,
    height: 55,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    background: '#ef5b34',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: '#ef5b34',
  },
})

function StepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <CheckIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const RedeemDetail = props => {
  const { redemption } = props
  const { reward, status, address, reason, shippingInfo } = redemption
  const intl = useIntl()

  const configActiveStep = () => {
    if (status === 'pending') return 0
    if (status === 'waiting') return 1
    if (status === 'shipping') return 2
    if (status === 'success') return 3
  }

  const activeStep = configActiveStep()
  const classes = useColorlibStepIconStyles()
  return (
    <Block
      p={['8px', 16]}
      display={'flex'}
      flexDirection={['column', 'row']}
      justifyContent={'space-between'}
    >
      <Text link onClick={() => props.goBack()}>
        {`< ${intl.formatMessage({ id: 'Back' })}`}
      </Text>
      <Block mt={[15, 0]}>
        {status !== 'cancel' && (
          <Block mb={16}>
            <Text variant={'h3'} mb={16}>
              {intl.formatMessage({ id: 'sendingStatus' })}
            </Text>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              style={{ padding: 0 }}
            >
              {['Redeem', 'Waiting', 'Shipping', 'Received'].map(label => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>
                    <Text variant={'caption'} fontWeight={'inherit'}>
                      {label}
                    </Text>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Block>
        )}
        {status === 'cancel' && (
          <Block mb={16}>
            <Text variant={'h3'} mb={16}>
              {intl.formatMessage({ id: 'sendingStatus' })}
            </Text>
            <div
              className={clsx(classes.root, {
                [classes.active]: true,
                [classes.completed]: true,
              })}
              style={{ margin: 'auto' }}
            >
              <Close />
            </div>
            <Text mb={16} mt={'8px'} fontWeight="bold" textAlign="center">
              {intl.formatMessage({ id: 'Cancel' })}
            </Text>
            <Block display="flex">
              <Text>{intl.formatMessage({ id: 'note' })}:</Text>
              <Block ml={15} style={{ wordBreak: 'break-word' }}>
                {reason.split(/\r?\n/g).map(text => (
                  <Text>{text}</Text>
                ))}
              </Block>
            </Block>
          </Block>
        )}
        {status === 'shipping' ||
          (status === 'success' && (
            <Block mb={16} style={{ wordBreak: 'break-word' }}>
              <Text variant={'h3'} mb={16}>
                {intl.formatMessage({ id: 'deliveryInformation' })}
              </Text>
              <Block p={16} borderRadius={8} border={'1px solid grey'}>
                <Text fontSize={13}>
                  {intl.formatMessage({ id: 'deliveryBy' })}:{' '}
                  {shippingInfo.shippingCompany}
                </Text>
                <Text fontSize={13}>
                  {intl.formatMessage({ id: 'trackingNumber' })}:{' '}
                  {shippingInfo.shippingId}
                </Text>
              </Block>
            </Block>
          ))}
        <Block mb={16}>
          <Text mb={16} variant={'h3'}>
            {intl.formatMessage({ id: 'deliveryAddress' })}
          </Text>
          <AddressCard address={address} notShowDefaultAddress />
        </Block>
      </Block>
      <Block>
        <Text mb={16} variant={'h3'}>
          {intl.formatMessage({ id: 'reward' })}
        </Text>
        <RewardCard reward={reward} />
      </Block>
    </Block>
  )
}

export default RedeemDetail

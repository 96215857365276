import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Modal } from 'components/molecules'
import { Block, Text, Button } from 'components/atoms'

const TransactionModal = ({ open, onClose, result, event }) => {
  const intl = useIntl()
  // console.log('codeResults', orderBy(codeResults, [ 'status', 'point' ], [ 'desc', 'asc' ]));
  // const renderFailCode = (failCodes) => {
  // 	if (failCodes.length > 0) {
  // 		return <Block>{failCodes.map(({ code }) => <Text>- {code}</Text>)}</Block>;
  // 	}
  // 	return null;
  // };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <Block width={['auto', 400]}>
          <Text textAlign={'center'} fontSize={18}>
            {intl.formatMessage({ id: 'youGet' })}
          </Text>
          <Text
            textAlign={'center'}
            fontSize={25}
            fontWeight={'bold'}
            mt={10}
            color={'#ef5d35'}
          >
            {result &&
              result
                .filter(code => code.status === 'success')
                .reduce((data, value) => {
                  return data + value.point
                }, 0)}
            {event && event.point}
          </Text>
          <Text
            textAlign={'center'}
            mt={'5px'}
            fontWeight={'bold'}
            color={'#ef5d35'}
          >
            Points
          </Text>
          {result && result.find(code => code.status === 'fail') && (
            <Text mt={20}>
              * {intl.formatMessage({ id: 'someCodeNotWork' })}
            </Text>
          )}
          {event && (
            <Block textAlign={'center'}>
              <Text mt={20}>
                {intl.formatMessage(
                  { id: 'toMainPage' },
                  { eventName: event.name }
                )}
              </Text>
              <Button.OutLined mt={20} onClick={() => onClose()}>
                {intl.formatMessage({ id: 'toMainPage' })}
              </Button.OutLined>
            </Block>
          )}
        </Block>
      }
    />
  )
}

export default TransactionModal

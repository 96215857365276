import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import 'moment/locale/th'
import 'moment/locale/en-gb'
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl'
import { Block, GatsbyImage } from 'components/atoms'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import { Menu } from '@material-ui/icons';
const Logo = 'logo.png'

const Header = ({ boxShadow = false }) => {
  // const [setShowMobileMenu] = React.useState(false);
  const intl = useIntl()
  const setMomentLocale = language => {
    if (language === 'en') {
      moment.locale('en-gb')
    } else {
      moment.locale('th')
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem('language')) {
      changeLocale(localStorage.getItem('language'))
      setMomentLocale(localStorage.getItem('language'))
    } else {
      changeLocale('th')
      moment.locale('th')
    }
  }, [])

  return (
    <IntlContextConsumer>
      {({ languages }) => {
        return (
          <Block
            id={'header'}
            position={'relative'}
            background={'white'}
            top={0}
            css={{
              transition: '0.3s',
              zIndex: 1000,
              width: '100%',
              boxShadow: boxShadow ? '0 4px 2px -2px lightgrey' : 'none',
            }}
            maxHeight={['auto', '13vh']}
          >
            <Block
              display={'flex'}
              maxWidth={['initial', 700, 960, 1200]}
              mx={'auto'}
              alignItems={'center'}
              justifyContent={'initial'}
            >
              <Block.Middle
                px={[16, 0]}
                minHeight={[64, 80]}
                width={['100%', '100%']}
                position={'relative'}
              >
                {/* <Block
						display={['block', 'none', 'none']}
						css={{ '.MuiSvgIcon-root': { fontSize: 24 } }}
						position={'absolute'}
					>
						<Menu onClick={() => setShowMobileMenu(true)} />
					</Block> */}
                <Block width={['30%', '100%']} m={['auto', 'none']}>
                  <Link to="/">
                    <GatsbyImage
                      src={Logo}
                      width={[85, 100]}
                      height={32}
                      m={'auto'}
                    />
                  </Link>
                </Block>
              </Block.Middle>
            </Block>
            <Block
              position={'absolute'}
              right={[12, 25]}
              top={['15.5px', '3vh']}
            >
              <FormControl>
                <Select
                  label="language"
                  onChange={event => {
                    // setLanguage(event.target.value)
                    localStorage.setItem('language', event.target.value)
                    changeLocale(event.target.value)
                    setMomentLocale(event.target.value)
                  }}
                  value={intl.locale || 'th'}
                >
                  {languages.map(languageData => (
                    <MenuItem value={languageData}>
                      {languageData.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Block>
          </Block>
        )
      }}
    </IntlContextConsumer>
  )
}

export default Header

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { Block, Button } from 'components/atoms'
import { Modal } from 'components/molecules'
import CakeOutlined from '@material-ui/icons/CakeOutlined'
import DateFnsUtils from '@date-io/date-fns'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

// CloudApi
import CloudApi from 'api/cloudApi'

// REDUX
import { getProfile } from 'modules/auth/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const EditProfileModal = ({ open, type, profile, getProfile, onClose }) => {
  const { birthDate, displayName } = profile

  const [newBirthDate, setNewBirthDate] = React.useState(
    birthDate ? moment(birthDate).format('YYYY-MM-DD') : null
  )
  const [gender, setGender] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [newDisplayName, setNewDisplayName] = React.useState(displayName)

  const checkTypeToSend = () => {
    if (type === 'displayName') {
      return { displayName: newDisplayName }
    } else if (type === 'birthday') {
      return {
        birthDate: newBirthDate
          ? moment(newBirthDate).format('YYYY MM DD')
          : null,
      }
    } else if (type === 'gender') {
      return { gender }
    }
  }

  const intl = useIntl()

  return (
    <Modal
      header
      title={intl.formatMessage({ id: 'editData' })}
      open={open}
      onClose={() => onClose()}
      content={
        <Block
          width={['auto', 400]}
          css={{
            '.MuiFormControl-root': {
              width: '100%',
            },
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {type === 'birthday' && (
              <Block
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                css={{
                  '.MuiSvgIcon-root': {
                    fontSize: 30,
                    opacity: 0.3,
                    '@media (max-width: 640px)': {
                      fontSize: 24,
                    },
                  },
                }}
              >
                <CakeOutlined style={{ marginTop: 16 }} />
                <Block ml={[15, 25]} width={'100%'}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={intl.formatMessage({ id: 'day/month/year' })}
                    value={newBirthDate}
                    InputProps={{
                      inputProps: { max: moment().format('YYYY-MM-DD') },
                    }}
                    maxDate={moment()}
                    onChange={date => setNewBirthDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Block>
              </Block>
            )}
            {type === 'gender' && (
              <Block width={'100%'} css={{ '.MuiInput-root': { width: 100 } }}>
                <Select
                  style={{ width: '100%' }}
                  value={gender}
                  onChange={event => setGender(event.target.value)}
                >
                  <MenuItem value={'male'}>
                    {intl.formatMessage({ id: 'male' })}
                  </MenuItem>
                  <MenuItem value={'female'}>
                    {intl.formatMessage({ id: 'female' })}
                  </MenuItem>
                  <MenuItem value={'other'}>
                    {intl.formatMessage({ id: 'other' })}
                  </MenuItem>
                  <MenuItem value={'rather not say'}>
                    {intl.formatMessage({ id: 'ratherNotSay' })}
                  </MenuItem>
                </Select>
              </Block>
            )}
            {type === 'displayName' && (
              <TextField
                value={newDisplayName}
                label={intl.formatMessage({ id: 'displayName' })}
                onChange={event => setNewDisplayName(event.target.value)}
              />
            )}
            <Block display={'flex'} mt={[15, 25]} justifyContent={'flex-end'}>
              <Button.OutLined onClick={() => onClose()} mr={25}>
                {intl.formatMessage({ id: 'Cancel' })}
              </Button.OutLined>
              <Button.Contain
                loading={loading}
                onClick={() => {
                  setLoading(true)
                  CloudApi.updateUserProfile(checkTypeToSend()).then(() => {
                    getProfile().then(() => {
                      setLoading(false)
                      onClose()
                    })
                  })
                }}
                disabled={
                  (type === 'birthday' && !newBirthDate) ||
                  (type === 'gender' && !gender) ||
                  (type === 'newDisplayName' && !newDisplayName)
                }
              >
                {intl.formatMessage({ id: 'submit' })}
              </Button.Contain>
            </Block>
          </MuiPickersUtilsProvider>
        </Block>
      }
    />
  )
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
      },
      dispatch
    )
)(EditProfileModal)
